import React, { useEffect, useState, useCallback, useRef} from 'react';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"
import firebaseDb from "../firebase-config";  
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css';
import AddEditEvent from './AddEditEvent'
import { IconButton, Snackbar, Alert, } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from "@mui/material";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';

// sleep function
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function Calendar({ showSidebar, setShowSidebar }) {
    const navigate = useNavigate();
    
    // whether or not the form is open
    const [openForm, setOpenForm] = useState(false);
    // whether or not the event text has been copied
    const [copied, setCopied] = useState(false);
    // used to make sure dateclick event doesn't open add/edit form while user is copying
    const [isCopying, setIsCopying] = useState(false);
    // for changing the form title based on whether you are adding / editing
    const [formType, setFormType] = useState("Add");
    const { venue } = useParams();
    const [events, setEvents] = useState([]);
    const [currentEvent, setCurrentEvent] = useState({});
    const calendarRef = useRef(null);
    const [hoveredDay, setHoveredDay] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0); // state to store scroll position

    // get events on render
    useEffect(() => {
        // Fetch events from Firebase (runs on mount)
        firebaseDb.child('database/events').orderByChild('venue').equalTo(venue).on('value', (snapshot) => {
            let obj = snapshot.val();
            let arr = Object.keys(obj).map((key) => {
                return { 
                    title: obj[key].stage,
                    start: obj[key].start,
                    end: obj[key].end,
                    extendedProps: {
                        date: obj[key].date,
                        stage: obj[key].stage,
                        performers: obj[key].performers,
                        startTime: obj[key].startTime,
                        email: obj[key].email,
                        endTime: obj[key].endTime,
                        price: obj[key].price,
                        venue: obj[key].venue,
                        eventID: key
                    }
                };
            });
            setEvents(arr);
        });
    }, []);
    
    useEffect(() => {
        if (openForm) {
            // Save current scroll position and prevent scrolling
            setScrollPosition(window.scrollY);  // Save current scroll position
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPosition}px`;
            document.body.style.width = '100%';
        } else {
            // Restore the scroll position after form closes
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth' // Smoothly scroll back to the saved position
            });
        }
    
        // Cleanup when component unmounts or when the form opens/closes
        return () => {
            document.body.style.position = '';
            document.body.style.top = '';
        };
    }, [openForm, scrollPosition]);
    
    
     
    // open add event form when a date is clicked
    const handleDateClick = (DateClickArg) => {
        // if user is copying, stop dateclick event
        if (isCopying) { return; }
        
        // Capture the scroll position before opening the form
        setScrollPosition(window.scrollY);

        // pass a mostly empty event object with only the date and venue properties filled out
        const event = {
            date: DateClickArg.dateStr,
            venue: venue
        };
        setFormType("Add");
        setCurrentEvent(event);
        setOpenForm(true);
        // automatically select Artist input field 200 ms after opening
        sleep(200).then(() => {document.getElementById("client").focus(); });
    }
    
    // open edit event form when an event is clicked
    const handleEventClick = (info) => {
        // Capture the scroll position before opening the form
        setScrollPosition(window.scrollY);

        // pass a filled out event object with the properties of the clicked event
        const event = {
            stage: info.event.extendedProps.stage,
            performers: info.event.extendedProps.performers,
            startTime: info.event.extendedProps.startTime,
            endTime: info.event.extendedProps.endTime,
            email: info.event.extendedProps.email,
            price: info.event.extendedProps.price,
            date: info.event.extendedProps.date,
            eventID: info.event.extendedProps.eventID,
            venue: info.event.extendedProps.venue,
            start: info.event.startStr,
            end: info.event.endStr
        };
        setFormType("Edit");
        setCurrentEvent(event);
        setOpenForm(true);
        // automatically select Artist input field 200 ms after opening
        sleep(200).then(() => {document.getElementById("client").focus(); });
    }

    // Restore scroll position when the form is closed
    useEffect(() => {
        if (!openForm) {
            window.scrollTo(0, scrollPosition);
        }
    }, [openForm, scrollPosition]);

    // run when user presses the copy button
    const handleCopy = async (event, formattedDate) => {
        setIsCopying(true);
        const eventsOnDay = events.filter(obj => {
            return ((obj.extendedProps.date === formattedDate) && (obj.extendedProps.stage !== undefined && obj.extendedProps.stage !== ""));
        });
        let copyText = "";
        
        eventsOnDay.sort((a, b) => a.extendedProps.startTime.substring(0, 2) - b.extendedProps.startTime.substring(0, 2)).forEach((eventOnDay) => {
            let start = new Date(eventOnDay.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).split(" ")[0].replace(/AM|PM/g, "").trim();
            let end = new Date(eventOnDay.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).split(" ")[0].replace(/AM|PM/g, "").trim();
            copyText += (eventOnDay.extendedProps.stage + "\n");
            copyText += to12HourFormat(start) + " to " + to12HourFormat(end) + "\n";
        })

        setTimeout(async () => { 
            await navigator.clipboard.writeText(copyText); 
            setCopied(true);
        }, 100)

        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(1000);
        setIsCopying(false);
    }

    // run when the copied alert is closed
    const handleCopiedClosed = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setCopied(false);
    }

    function to12HourFormat(timeString) {
        const [hour, minute] = timeString.split(":");
        const hours = parseInt(hour, 10);
        let hour12 = hours % 12;
        if (hour12 === 0) {
            hour12 = 12;
        }
        return `${hour12}:${minute}`;
    }

    const renderCopyButton = (content) => {
        const date = content.date;
    
        let year = date.toLocaleString("default", { year: "numeric" });
        let month = date.toLocaleString("default", { month: "2-digit" });
        let day = date.toLocaleString("default", { day: "2-digit" });
        let formattedDate = year + "-" + month + "-" + day;
    
        return (
            <div 
                onMouseEnter={() => setHoveredDay(formattedDate)}
                onMouseLeave={() => setHoveredDay(null)}
                style={{ display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "flex-end", gap: "0px", position: 'relative' }}
            >
                <IconButton 
                    id={formattedDate} 
                    onClick={(event) => handleCopy(event, formattedDate)}
                    aria-label="copy"
                    style={{
                        padding: "3px",
                        visibility: hoveredDay === formattedDate ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: '8px',
                        top: '50%',
                        transform: 'translateY(-50%)'
                    }}
                >
                    <ContentCopyIcon />
                </IconButton>
                <span style={{ marginLeft: "20px" }}>{content.dayNumberText}</span>
            </div>
        );
    }

    const downloadPDF = () => {
        const currentMonthName = getCurrentMonthName();
        html2canvas(document.querySelector('#calendar')).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const filename = `${currentMonthName}Calendar.pdf`;
            const pdf = new jsPDF({ orientation: 'landscape' });
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            const canvasRatio = canvasWidth / canvasHeight;
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            let imgWidth = pdfWidth;
            let imgHeight = pdfWidth / canvasRatio;
            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
                imgWidth = pdfHeight * canvasRatio;
            }
            pdf.addImage(imgData, 'PNG', 20, 0, imgWidth, imgHeight);
            pdf.setFontSize(20);
            pdf.setTextColor("#264a81");
            pdf.text("Live Music Nightly", imgWidth/8, 8);
            pdf.text("On The Patio", 5 + imgWidth/8, 16);
            pdf.text("Live Music Nightly", 5 + imgWidth*.75, 8);
            pdf.text("On The Patio", 10 + imgWidth*.75, 16);
            pdf.save(filename);
        });
    };

    const getCurrentMonthName = () => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const currentDate = calendarApi.getDate();
           
            const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
            
            return currentMonth;
        }
        return '';
    };

    

    return (
        <>
            <style jsx>{`   
                

                .fc-border {
                  color: #E0F2FF; 

                }
                .fc-day-sat, .fc-day-sun {
                    background-color: #E0F2FF; 
                }
                
                .fc-col-header-cell {
                    background-color: #264a81;
                    font-size: 1.7vw;
                }
                
                .fc-col-header-cell-cushion {
                    color: #ffffff;
                }
                         
                .fc-event-title, .fc-event .fc-title {
                    white-space: normal;
                    overflow: hidden;
                    lineHeight: 1;
                    justify-content: center;
                    text-align: center;
                    overflow: visible;
                    overflow-wrap: anywhere;
                }'

                .fc-daygrid-day.fc-day-other .fc-event {
                    display: none;
                    float: left;
                }

                
                .fc-daygrid-day-number {
                    float: left; /* Align to the left */
                    margin-right: auto;
                    font-size: 1.5vw;
                    color: black;
                    fontWeight: bold;
                }
                .fc-event{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    overflow: hidden;
                    overflow-wrap: anywhere;
                    white-space: initial;
                } 
            
                .fc-toolbar-title {
                    font-size: 6vw !important;
                    color: #264a81;
                }
                .fc-timegrid-slot-minor {
                    border-bottom: 1px solid var(--fc-border-color, black) !important;
                    border-top: 1px dotted var(--fc-border-color, black) !important;
                }
              

             
                }
            `}</style>
    <div className='content' >
    <div className='calendarButtons' style={{paddingLeft: '30px', paddingTop:'15px'}}>

                <Button style={{ paddingRight: '30px' }}  startIcon={<KeyboardDoubleArrowLeftIcon />}  onClick={() => navigate('/venues')}> Return to Venues </Button>
                <Button variant="contained" endIcon ={<DownloadIcon />} onClick={downloadPDF}>Download as PDF</Button>
    </div>
                <div id="calendar" //sstyle={{paddingLeft: '15px', paddingRight: '15px', paddingTop: '15px', paddingBottom: '15px'}} 
                >

                    <div className="calendar-container">
                    <FullCalendar
                        ref={calendarRef}
                        events={events}
                        headerToolbar={{
                            center: 'title',
                            right: 'prev,next today',
                            left: 'prev,next today',
                        }}
                        eventContent={({ event }) => (
                            <div 
                                style={{ 
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <strong style={{ fontSize: '1.45vw', color: 'black'}}>{event.title}</strong>
                                <i style={{ fontSize: '1.35vw', fontWeight: 'bold', color: 'black'}}>{to12HourFormat(event.extendedProps.startTime)} - {to12HourFormat(event.extendedProps.endTime)}</i>
                            </div>
                        )}
                        dayHeaderFormat={{ weekday: 'long' }}      
                        contentHeight="auto"
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        dayCellContent={renderCopyButton}
                        showNonCurrentDates = {false}
                                        

                        
                    />
                    </div>
                </div>
                
                <AddEditEvent
                    open={openForm}
                    setOpen={setOpenForm}
                    formType={formType}
                    event={currentEvent}
                />
                
                <Snackbar
                    open={copied}
                    autoHideDuration={3000}
                    onClose={handleCopiedClosed}
                >
                    <Alert onClose={handleCopiedClosed} severity="success" sx={{ width: '100%' }}>
                        Copied Text!
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
            };
            export default Calendar
