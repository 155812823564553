import React, { useState, useEffect } from 'react';
import firebaseDb from "../firebase-config";  
import { Autocomplete, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl,
         FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { AttachMoney } from '@mui/icons-material';

export default function FormDialog(props) {
    const { open, setOpen, formType, event } = props;

    const title = `${formType} Event`;
    const [stage, setStage] = useState(event.stage);
    const [date, setDate] = useState(event.date);
    const tempMonth = (date !== undefined) ? new Date(event.date.replace(/-/g, '/')).toLocaleString('default', { month: 'long' }) : "";
    const tempYear = (date !== undefined) ? new Date(event.date.replace(/-/g, '/')).getFullYear() : "";
    const tempDay = (date !== undefined) ? new Date(event.date.replace(/-/g, '/')).toLocaleString('default', { weekday: 'long' }) : "";
    const dayOfWeek = (date !== undefined) ? new Date(event.date).getDay()+1 : "";
    const [weekDay, setWeekDay] = useState(new Date(event.date).getDay()+1);
    const [month, setMonth] = useState(tempMonth);
    const [year, setYear] = useState(tempYear);
    const [startTime, setStartTime] = useState(event.startTime);
    const [endTime, setEndTime] = useState(event.endTime);
    const [price, setPrice] = useState(event.price);
    const [performers, setPerformers] = useState(event.performers);
    const [email, setEmail] = useState(event.email);
    const [clients, setClients] = useState({});
    const [clientsList, setClientsList] = useState();
    const [events, setEvents] = useState({});
    const [isTimeCustom, setIsTimeCustom] = useState(false);
    const [isPriceCustom, setIsPriceCustom] = useState(false);
    const [matchingEvent, setMatchingEvent] = useState(false);
    const [matchingEventStartTime, setMatchingEventStartTime] = useState(event.startTime);

    const d = new Date();
    let day = d.getDay();

    useEffect(() => {
        firebaseDb.child('database/clients').on('value', snapshot => {  
            if (snapshot.val() != null) {  
                setClients(snapshot.val());
            }
        });
    }, []);

    useEffect(() => {
        firebaseDb.child('database/events').on('value', snapshot => {  
            if (snapshot.val() != null) {  
                setEvents(snapshot.val());
            }
        });
    }, []);
         
    useEffect(() => {
        let tempClientsList = [];
        tempClientsList = Object.keys(clients).map((key) => {
            return {
                label: clients[key].stage,
                emailLabel: clients[key].email,
                labelPerformer: clients[key].performers ? clients[key].performers.join(", ") : "",
                id: key
            };
        });
        setClientsList(tempClientsList);
    }, [clients]);

    useEffect(() => {
        setWeekDay(dayOfWeek);

        const defaultValues = (() => {
            setMatchingEvent(false);
            Object.keys(events).map((key) => {
                if (events[key].date === event.date) { 
                    setMatchingEventStartTime(events[key].startTime);
                    setMatchingEvent(true);
                }
                return matchingEvent;
            });

            var tempStartTime = "17:00";
            var tempEndTime = "19:30";
            if (matchingEvent === true && matchingEventStartTime === "17:00") {
                tempStartTime = "19:30";
                tempEndTime = "22:00";
            }
            return {
                stage: "",
                email: "NO_EMAIL_FOR_ARTIST",
                performers: "",
                time: tempStartTime + "-" + tempEndTime,
                startTime: tempStartTime,
                endTime: tempEndTime,
                price: 185
            };
        })();

        setStage((event.stage !== undefined) ? event.stage : defaultValues["stage"]);
        setEmail((event.email !== undefined) ? event.email : defaultValues["email"]);
        setPerformers((event.performers !== undefined) ? event.performers : defaultValues["performers"]);
        setDate(event.date);
        const tempMonth = (event.date !== undefined) ? new Date(event.date.replace(/-/g, '/')).toLocaleString('default', { month: 'long' }) : "";
        const tempYear = (event.date !== undefined) ? new Date(event.date.replace(/-/g, '/')).getFullYear() : "";
        setMonth(tempMonth);
        setYear(tempYear);

        if (event.startTime !== undefined && event.endTime !== undefined) {
            setStartTime(event.startTime);
            setEndTime(event.endTime);
            const tempTime = event.startTime + "-" + event.endTime;
            setIsTimeCustom((tempTime !== "17:00-19:30" && tempTime !== "19:30-22:00") ? true : false);
        } else {
            setStartTime(defaultValues["startTime"]);
            setEndTime(defaultValues["endTime"]);
            setIsTimeCustom(false);
        }

        if (event.price !== undefined) {
            const tempPrice = event.price;
            setPrice(tempPrice);
            setIsPriceCustom((tempPrice !== 185 && tempPrice !== 380) ? true : false);
        } else {
            setPrice(defaultValues["price"]);
            setIsPriceCustom(false);
        }
    }, [open, formType, event, weekDay]);

    const handleTimeChange = (event) => {
        if (event.target.value !== "custom") {
            setIsTimeCustom(false);
            const [tempStart, tempEnd] = event.target.value.split("-");
            setStartTime(tempStart);
            setEndTime(tempEnd);
        } else {
            setIsTimeCustom(true);
            setStartTime(undefined);
            setEndTime(undefined);
        }
    };

    const handlePriceChange = (event) => {
        if (event.target.value !== "custom") {
            setIsPriceCustom(false);
            setPrice(parseInt(event.target.value));
        } else {
            setIsPriceCustom(true);
            setPrice(undefined);
        }
    };

    const handleClose = () => { setOpen(false); };

    const submitEvent = () => {
        let clientID = "";
        Object.keys(clientsList).map((key) => {
            if (clientsList[key].label === stage) { clientID = clientsList[key].id };
            return clientID;
        });
        const newEvent = {
            stage: stage,
            email: email,
            performers: performers,
            date: date,
            startTime: startTime,
            endTime: endTime,
            price: price,
            clientID: clientID,
            venue: event.venue,
            start: (date + "T" + startTime),
            end: (date + "T" + endTime),
            month: month,
            year: year,
            venue_month_year: (event.venue + "__" + month + "__" + year)
        };
        if (formType === "Edit") {
            firebaseDb.child('database/events/' + event.eventID).set(newEvent);
        } else {
            firebaseDb.child('database/events').push(newEvent);
        }
        setOpen(false);
    };

    const deleteEvent = () => {
        firebaseDb.child('database/events/' + event.eventID).remove();
        setOpen(false);
    }

    const currentClient = clientsList?.find(client => client.label === stage);

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ width: 500 }}>
                        {clientsList !== undefined && clientsList.length !== 0 && (
                            <Autocomplete
                                id="client"
                                options={clientsList.sort((a, b) => a.label - b.label)}
                                value={currentClient}
                                EmailVal={email}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        const { label, emailLabel, labelPerformer } = newValue;
                                        if (label) {
                                            setStage(label);
                                            if (JSON.stringify(newValue.label).includes("\u00A0")) {
                                                setPrice(380);
                                            }
                                        }
                                        if (emailLabel) {
                                            setEmail(emailLabel);
                                        }
                                        if (labelPerformer) {
                                            setPerformers(labelPerformer);
                                        }
                                    } else {
                                        if (formType === "Add") {
                                            setStage("");
                                            setEmail("NO_EMAIL_FOR_ARTIST");
                                            setPerformers("");
                                        }
                                    }
                                }}
                                isOptionEqualToValue={(option, value) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        label="Artist"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        required
                                        onChange={(event) => {
                                            if (event.target.name === "stage") {
                                                setStage(event.target.value);
                                            } else if (event.target.name === "email") {
                                                setEmail(event.target.value);
                                            }
                                        }}
                                    />
                                )}
                            />
                        )}
                        <TextField 
                            margin="dense"
                            id="date"
                            label="Date"
                            type="date"
                            value={date}
                            required
                            fullWidth
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => { setDate(event.target.value) }}
                        />
                        <FormControl required>
                            <FormLabel id="time-radio-buttons-group-label">Time</FormLabel>
                            <RadioGroup
                                aria-labelledby="time-radio-buttons-group-label"
                                value={isTimeCustom ? "custom" : (startTime + "-" + endTime)}
                                name="time-radio-buttons-group"
                                onChange={handleTimeChange}
                            >
                                {(() => {
                                    if (formType === "Add" && matchingEvent === true && matchingEventStartTime === "17:00") {
                                        return;
                                    }
                                    switch (dayOfWeek) {
                                        case 1:
                                        case 2:
                                        case 3:
                                        case 4:
                                        case 5:
                                        case 6:
                                        case 7:
                                            return <FormControlLabel value="17:00-19:30" control={<Radio />} label="5:00 PM - 7:30 PM" />;
                                        default:
                                            return <p>This is not a day of the week</p>;
                                    }
                                })()}
                                {(() => {
                                    if (formType === "Add" && matchingEvent === true && matchingEventStartTime === "19:00") {
                                        return;
                                    }
                                    switch (dayOfWeek) {
                                        case 1:
                                        case 2:
                                        case 3:
                                        case 4:
                                        case 5:
                                        case 6:
                                        case 7:
                                            return <FormControlLabel value="19:30-22:00" control={<Radio />} label="7:30 PM - 10:00 PM" />;
                                        default:
                                            return <p>This is not a day of the week</p>;
                                    }
                                })()}
                                <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                            </RadioGroup>
                            {isTimeCustom && <Stack spacing={2} sx={{ width: 500 }} direction="row" >
                                <TextField 
                                    margin="none"
                                    id="start-time"
                                    label="Start Time"
                                    value={startTime}
                                    type="time"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={event => { setStartTime(event.target.value) }}
                                />
                                <TextField
                                    margin="none"
                                    id="end-time"
                                    label="End Time"
                                    value={endTime}
                                    type="time"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(event) => { setEndTime(event.target.value) }}    
                                />
                            </Stack>}
                        </FormControl>
                        <FormControl required>
                            <FormLabel id="price-radio-buttons-group-label">Price</FormLabel>
                            <RadioGroup
                                aria-labelledby="price-radio-buttons-group-label"
                                value={isPriceCustom ? "custom" : price}
                                name="price-radio-buttons-group"
                                onChange={handlePriceChange}
                            >
                                {(() => {
                                    switch (dayOfWeek) {
                                        case 1:
                                        case 2:
                                        case 3:
                                        case 4:
                                        case 5:
                                        case 6:
                                        case 7:
                                            return <FormControlLabel value="185" type="number" control={<Radio />} label="$185" />;
                                        default:
                                            return <p>This is not a day of the week</p>;
                                    }
                                })()}
                                {(() => {
                                    switch (dayOfWeek) {
                                        case 1:
                                        case 2:
                                        case 3:
                                        case 4:
                                        case 5:
                                        case 6:
                                        case 7:
                                            return <FormControlLabel value="380" type="number" control={<Radio />} label="$380" />;;
                                        default:
                                            return <p>This is not a day of the week</p>;
                                    }
                                })()}
                                <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                            </RadioGroup>
                            {isPriceCustom && <Stack spacing={2} sx={{ width: 500 }} direction="row" >
                                <TextField
                                    margin="dense"
                                    id="custom-price"
                                    value={price}
                                    label="Price"
                                    fullWidth
                                    variant="standard"
                                    onChange={(event) => { setPrice(parseFloat(event.target.value)) }}
                                    type="number"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <AttachMoney />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>}
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {formType === "Edit" && <Button color="error" onClick={deleteEvent}>Delete</Button>}
                    <div style={{flex: '1 0 0'}} />
                    <Button onClick={submitEvent}>Submit</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
